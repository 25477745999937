import React from "react"
import { graphql } from "gatsby"
import { Layout } from "../components/layout"
import { GridOneText } from "../components/grids"
import styled from "styled-components"
import { BLOCKS } from "@contentful/rich-text-types"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { H1UppercaseGrey } from "../components/headings"

export default function Dataprotection({ data }) {
    const TextDiv = styled.div`
        text-align: left;
        font-size: 1rem;

        @media screen and (max-width: 813px) {
            font-size: 0.9rem;

            h1 {
                font-size: 1.5rem;
                text-align: center;
            }
        }
    `
    const options = {
        renderNode: {
            [BLOCKS.HEADING_1]: (node, children) => <H1UppercaseGrey>{children}</H1UppercaseGrey>,
        },
        renderText: text => {
            return text.split('\n').reduce((children, textSegment, index) => {
                return [...children, index > 0 && <br key={index} />, textSegment];
            }, []);
        },
    }
    return (
        <Layout title="Datenschutzerklärung" lang="de" actSite="dataprotection">
            <GridOneText>
                <TextDiv>
                    {documentToReactComponents(data.dataprot.content.json, options)}
                </TextDiv>
            </GridOneText>
        </Layout>
    )
}

export const query = graphql`
    query {
        dataprot: contentfulTextOnly(title: {eq: "Datenschutz"}, node_locale: {eq: "de"}) {
            content {
                json
            }
        }
    }
`;
